<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="source:" class="mb5">
                <el-select v-model="filterForm.sourceIdList" clearable filterable multiple>
                  <el-option
                    v-for="item in sourceListAll"
                    :key="item.id"
                    :value="item.id"
                    :label="item.id + '-' + item.company"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="prod:" class="mb5">
                <el-input
                  v-model="filterForm.prods"
                  placeholder="prod,eg:A,B,C"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="eventName:" class="mb5">
                <el-row>
                  <el-col>
                    <el-input
                      v-model="filterForm.eventName"
                      placeholder="please input eventName"
                      clearable
                    ></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="countries:" class="mb5">
                <el-row>
                  <el-col>
                    <el-input
                      v-model="filterForm.countries"
                      placeholder="please input countries"
                      clearable
                    ></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="period:" class="mb5">
                <el-date-picker
                  v-model="filterForm.financeCycle"
                  type="month"
                  value-format="yyyy-MM"
                  placeholder="period"
                  :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-form-item label-width="0" class="mb5">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
                <el-button type="info" :loading="loading.list" @click="downloadFie(1)"
                  >download</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width: 100%"
          stripe
          border
          :data="list"
          tooltip-effect="dark"
          aria-setsize="mini"
          :span-method="objectSpanMethod"
          max-height="750"
          size="mini"
          class="mb10"
          :row-style="{ height: '50px' }"
          @sort-change="sortChange"
        >
          <el-table-column label="period" prop="yearMonth" align="center"></el-table-column>
          <el-table-column label="sourceId" prop="sourceId" align="center"></el-table-column>
          <el-table-column label="source" prop="sourceName" align="center"></el-table-column>
          <el-table-column label="sourceRate" prop="sourceRate" align="center">
            <template slot-scope="scope">
              {{ scope.row.sourceRate }}
              <i class="el-icon-edit" @click="handleAccountType(scope.row)"></i>
            </template>
          </el-table-column>
          <el-table-column label="appid" prop="prod" align="center"></el-table-column>
          <el-table-column label="countries" prop="countries" align="center"></el-table-column>
          <el-table-column label="appName" prop="appName" align="center"></el-table-column>
          <el-table-column label="eventName" prop="eventName" align="center"></el-table-column>
          <el-table-column label="payout" prop="payout" align="center"></el-table-column>
          <el-table-column
            label="clicks"
            prop="clickCount"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column label="evr" prop="evr" align="center">
            <template slot-scope="scope"> {{ formatNumber(scope.row.evr) }}% </template>
          </el-table-column>
          <el-table-column label="cr" prop="cr" align="center">
            <template slot-scope="scope"> {{ formatNumber(scope.row.cr) }}% </template>
          </el-table-column>
          <el-table-column
            label="confirm revenue"
            prop="confirmNum"
            align="center"
          ></el-table-column>
          <el-table-column label="ecpc" prop="ecpc" align="center" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.ecpc }}
              <el-tooltip class="item" effect="dark" placement="top-start">
                <div slot="content">ecpc= （流水/click)*1000000 </div>
                <i class="el-icon-warning-outline" /> </el-tooltip
            ></template>
          </el-table-column>
          <el-table-column
            label="clic+impression"
            prop="totalClickImpression"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column label="ecpm+ecpc" prop="totalEcpm" align="center"></el-table-column>
          <el-table-column label="dedution" prop="dedution" align="center" sortable="custom">
            <template slot-scope="scope"> {{ formatNumber(scope.row.dedution) }}% </template>
          </el-table-column>
        </el-table>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
      <!--编辑accountManager 类型模态框-->
      <el-dialog title="提示" :visible.sync="accountTypeVisible" width="30%">
        <!--      <el-input v-model="businessType" placeholder="please add here"></el-input>-->
        <el-form :model="sourceRateType">
          <el-form-item label="Source Rate">
            <el-select v-model="accountType" placeholder="Please select" class="w100">
              <el-option
                v-for="item in sourceRateList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="accountTypeVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateAccountType()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import { updateSourceRate } from 'api/source/overall';
  import { selectProds, selectApps } from 'api/product/overall';
  // import { mapState, mapActions } from 'vuex';
  import { fetchComparePricesPage } from 'api/finance/bussinessProcess';
  import Pagination from '@/components/pagination';
  // import GroupSelect from '@/components/GroupSelect';
  import { getSourceListALL } from 'api/source/index';
  import { filterObject } from 'utils/utils';
  import qs from 'querystring';
  function filterArray(item) {
    const valueArray = this.rule.filter((prop) => {
      return item[prop] === this.data[prop];
    });
    if (valueArray.length === this.rule.length) {
      return true;
    } else {
      return false;
    }
  }
  export default {
    name: 'comparePrices',
    components: {
      Pagination,
      // GroupSelect,
    },
    data() {
      return {
        filterForm: {
          page: 1,
          pageSize: 20,
          dateRange: [],
          financeCycle: '',
        },
        total: 0,
        postData: {},
        rules: { financeCycle: [{ required: true, message: 'financeCycle不能为空' }] },
        list: [],
        loading: {
          list: false,
          submitBtn: false,
        },
        accountTypeVisible: false,
        accountType: '',
        sourceRateType: {
          id: 0,
          rate: '',
        },
        sourceRateList: ['A', 'B', 'C'],
        prodList: [],
        sourceListAll: [],
        appList: [],
        spanRule: {
          1: ['sourceId', 'prod', 'countries'],
          2: ['sourceId', 'prod', 'countries'],
          3: ['sourceId', 'prod', 'countries'],
          4: ['sourceId', 'prod', 'countries'],
          5: ['sourceId', 'prod', 'countries'],
          8: ['sourceId', 'prod', 'countries', 'eventName'],
          9: ['sourceId', 'prod', 'countries'],
          10: ['sourceId', 'prod', 'countries'],
          11: ['sourceId', 'prod', 'countries'],
          12: ['sourceId', 'prod', 'countries'],
          13: ['sourceId', 'prod', 'countries'],
          14: ['sourceId', 'prod', 'countries'],
          15: ['sourceId', 'prod', 'countries'],
          16: ['sourceId', 'prod', 'countries'],
        },
      };
    },
    mounted() {
      // this.getSourceIdsList();
      this.getsourceListAll();
      this.prodsList();
      this.selectAppsList();
      this.getDefaultTime();
      this.getList();
    },
    computed: {
      // ...mapState('source', {
      //   sourcesList: (state) => state.sourcesList,
      //   sourceLoading: (state) => state.sourceLoading,
      // }),
    },
    watch: {
      'filterForm.dateRange': function(newVal) {
        if (!newVal || newVal.length === 0) {
          this.flag = false;
        }
      },
    },
    methods: {
      // get Source
      // ...mapActions('source', ['getSourceIdsList']),
      getList(page) {
        let period = this.filterForm.financeCycle;
        if (typeof period === 'undefined' || period == null || period === '') {
          this.$message.error('period is null');
        }
        if (page) {
          this.filterForm.page = page;
        }
        let param = { ...this.filterForm };
        if (this.filterForm.sourceIdList) {
          param.sourceList = this.filterForm.sourceIdList.toString();
        }
        if (this.filterForm.prods) {
          param.prods = this.filterForm.prods.toString();
        }
        if (this.filterForm.eventName) {
          param.eventName = this.filterForm.eventName;
        }
        if (this.filterForm.appNames) {
          param.appNameList = this.filterForm.appNames.toString();
        }
        param.startTime = this.filterForm.financeCycle;
        param.endTime = this.filterForm.financeCycle;
        this.loading.list = true;
        fetchComparePricesPage(param)
          .then((res) => {
            if (res.code === 200) {
              this.list = res.result;
              this.total = res.total;
            } else {
              this.$message.error(res.message);
            }
            this.loading.list = false;
          })
          .catch((e) => {
            this.loading.list = false;
            this.$message.error(e);
          });
      },
      // 下载excel表格
      downloadFie() {
        this.$confirm('最多下载10万条数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            let param = { ...this.filterForm };

            if (this.filterForm.sourceIdList) {
              param.sourceList = this.filterForm.sourceIdList.toString();
            }
            if (this.filterForm.prods) {
              param.prods = this.filterForm.prods.toString();
            }
            if (this.filterForm.eventName) {
              param.eventName = this.filterForm.eventName;
            }
            if (this.filterForm.appNames) {
              param.appNameList = this.filterForm.appNames.toString();
            }
            param.startTime = this.filterForm.financeCycle;
            param.endTime = this.filterForm.financeCycle;
            param = filterObject(param);
            let strParam = qs.stringify(param);
            let url = '/api/finance/searchComparePricesDownload?' + strParam;
            window.open(url, '_blank');
          })
          .catch((error) => {
            this.$message({
              type: 'info',
              message: error.message,
            });
          });
      },
      // 获取prods
      prodsList() {
        selectProds().then((res) => {
          this.prodList = res.result;
        });
      },
      getsourceListAll() {
        getSourceListALL().then((res) => {
          this.sourceListAll = res.result;
        });
      },
      formatNumber(value) {
        if (value === null || value === undefined) {
          return '';
        }
        return (value * 100).toFixed(2);
      },
      selectAppsList() {
        selectApps().then((res) => {
          this.appList = res.result;
        });
      },
      handleSizeChange(size) {
        this.filterForm.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.filterForm.page = currentPage;
        this.getList();
      },
      getDefaultTime() {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 2); // 设置日期为上上月
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const formattedMonth = month < 10 ? `0${month}` : month;
        let ddr = `${year}-${formattedMonth}`;
        this.filterForm.financeCycle = ddr; // 设置默认起始日期
      },

      handleAccountType(row) {
        this.accountType = row.sourceRate;
        this.accountTypeVisible = true;
        this.sourceRateType.id = row.sourceId;
      },
      updateAccountType() {
        this.sourceRateType.rate = this.accountType;
        updateSourceRate({ ...this.sourceRateType }).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: response.message,
              type: 'success',
            });
            this.getList();
            this.accountTypeVisible = false;
          } else {
            this.$message.error('Update Error:' + response.messsage);
          }
        });
      },

      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (row || column) {
          1 == 1;
        }

        if (Object.keys(this.spanRule).includes(columnIndex.toString())) {
          // filter验证数组
          const currentTable = {
            rule: this.spanRule[columnIndex],
            data: this.list[rowIndex],
          }; // 该单元格是否被合并 true 合并， false : 不合并
          let chooseSpan = false;
          if (rowIndex !== 0) {
            chooseSpan = filterArray.call(currentTable, this.list[rowIndex - 1]);
          }
          if (chooseSpan) {
            return { rowspan: 0, colspan: 1 };
          } else {
            return {
              rowspan: this.list.filter(filterArray, currentTable).length,
              colspan: 1,
            };
          }
        }
        return { rowspan: 1, colspan: 1 };
      },
      // 排序监听
      sortChange(column) {
        if (column.prop !== null && column.order !== null) {
          this.filterForm.sorting = column.prop;
          this.filterForm.sortType = column.order === 'ascending' ? 'asc' : 'desc';
        }
        this.getList();
      },
    },
  };
</script>

<style>
  .table-row {
    height: 50px;
  }
</style>
